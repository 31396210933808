<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      
      <div class="content">
        <Header title="集點卡" :logo="meta.header_image" />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Page/Liff/Shared/Header";
import { mapGetters } from "vuex";
import themeColor from "@/mixins/liff/themeColor";

export default {
  mixins: [themeColor],
  components: {
    Header,
  },
  computed: {
    ...mapGetters("liffGeneral", ["themeConfig", "meta"]),
  },
  data() {
    return {
      themeConfigPage: "rewards",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--s-gray-dark);
  background-color: var(--liff-layout_bg_color);
}

.content {
  padding-bottom: 24px;
  background: var(--liff-content_bg_color);
}
</style>
